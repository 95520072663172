import React, { useState, useEffect, useCallback, MouseEvent } from "react"
import tamLogo from "../../images/top/tam_logo.svg"
import arrow01 from "../../images/top/arrow_01.svg"
import snsF from "../../images/top/sns_f.svg"
import snsX from "../../images/top/sns_x.svg"
import snsI from "../../images/top/sns_i.svg"
import snsV from "../../images/top/sns_v.svg"

import Styles from "./index.module.scss"
import { useLocation } from "@reach/router"

export const Header: React.VFC = () => {
  const [showLink, setShowLink] = useState(false)
  const [showNav, setShowNav] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const header = document.getElementById("headerWrap")

    // スクロール値の取得
    const getScroll = () => {
      const currentScrollMount = Math.max(
        window.scrollY,
        document.documentElement.scrollTop,
        document.body.scrollTop
      )
      if (currentScrollMount > 10 && header) {
        header.style.backgroundColor = "#fff"
        header.style.height = "100%"
      } else {
        header ? (header.style.backgroundColor = "transparent") : null
      }
    }
    // returnで忘れずにスクロールイベントの削除
    window.addEventListener("scroll", getScroll)
    return () => window.removeEventListener("scroll", getScroll)
  }, [])

  const anchorScroll = (href: string) => {
    const targetEl = document.getElementById(href)
    if (targetEl) {
      const currentPosition = window.scrollY
      const targetTop = targetEl.getBoundingClientRect().top
      const headerElement = document.getElementById("header")
      const headerHeight = headerElement ? headerElement.clientHeight : 0
      const targetPosition = currentPosition + targetTop - headerHeight
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      })
    }
  }

  // 外部リンクのページ内スクロール
  useEffect(() => {
    if (location.hash) {
      // レンダリングでスクロールが上手くいかなったので0.5秒後スクロール開始
      setTimeout(() => {
        const href = location.hash.substring(1)
        anchorScroll(href)
      }, 500)
    }
  }, [])
  // ページ内スクロール
  const handleClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>, href: string) => {
      e.preventDefault()
      anchorScroll(href)
      setShowNav(false)
    },
    []
  )

  // ハンバーガーメニューの開閉
  const toggleNav = useCallback(() => {
    setShowNav(!showNav)
  }, [showNav])

  return (
    <div>
      <header className={Styles.pHeader}>
        <div className={Styles.pHeaderInner}>
          <h1 className={Styles.pHeaderLogo}>
            <a
              className={Styles.pHeaderLogoLink}
              href="https://www.tam-tam.co.jp/"
              rel="noopener noreferrer"
            >
              <img
                className={Styles.pHeaderLogoImage}
                src={tamLogo}
                alt="TAM"
              />
            </a>
          </h1>
          <button
            className={`${Styles.pHeaderMenuButton} ${
              showNav ? Styles.isActive : ""
            }`}
            type="button"
            onClick={toggleNav}
            aria-expanded={showNav}
            aria-controls="globalNav"
            aria-label={showNav ? "メニューを閉じる" : "メニューを開く"}
          ></button>
          <div className={`${Styles.pHeaderNav} ${Styles.uOnlyPc}`}>
            <ul className={Styles.pHeaderNavList}>
              <li className={Styles.pHeaderNavItem}>
                <a
                  className={Styles.pHeaderNavLink}
                  href="https://www.tam-tam.co.jp/work/"
                  rel="noopener noreferrer"
                >
                  実績紹介
                </a>
              </li>
              <li className={Styles.pHeaderNavItem}>
                <a
                  className={Styles.pHeaderNavLink}
                  href="https://www.tam-tam.co.jp/service/"
                  rel="noopener noreferrer"
                >
                  サービス案内
                </a>
              </li>
              <li className={Styles.pHeaderNavItem}>
                <a
                  className={Styles.pHeaderNavLink}
                  href="https://www.tam-tam.co.jp/about/"
                  rel="noopener noreferrer"
                >
                  TAMについて
                </a>
              </li>
              <li className={Styles.pHeaderNavItem}>
                <a
                  className={Styles.pHeaderNavLink}
                  href="https://www.tam-tam.co.jp/recruit/"
                  rel="noopener noreferrer"
                >
                  採用情報
                </a>
              </li>
              <li className={Styles.pHeaderNavItem}>
                <a
                  className={Styles.pHeaderNavLink}
                  href="https://www.tam-tam.co.jp/stories/"
                  rel="noopener noreferrer"
                >
                  記事・トピック
                </a>
              </li>
            </ul>
          </div>
        </div>
        <nav
          id="globalNav"
          className={`${Styles.pNav} ${showNav ? Styles.isOpen : ""}`}
          aria-hidden={!showNav}
        >
          <div className={Styles.pNavBg}></div>
          <div className={Styles.pNavInner}>
            <div className={Styles.pNavListWrap}>
              <ul className={Styles.pNavList}>
                <li className={`${Styles.pNavItem} ${Styles.uOnlySp}`}>
                  <a
                    className={Styles.pNavLink}
                    href="https://www.tam-tam.co.jp/"
                    rel="noopener noreferrer"
                  >
                    <span className={Styles.pNavTitle}>ホーム</span>
                    <span className={Styles.pNavSubTitle}>HOME</span>
                    <img
                      className={`icon ${Styles.pNavLinkIcon}`}
                      src={arrow01}
                      alt=""
                      role="presentation"
                    />
                  </a>
                </li>
                <li className={Styles.pNavItem}>
                  <a
                    className={Styles.pNavLink}
                    href="https://www.tam-tam.co.jp/work/"
                    rel="noopener noreferrer"
                  >
                    <span className={Styles.pNavTitle}>実績紹介</span>
                    <span className={Styles.pNavSubTitle}>WORK</span>
                    <img
                      className={`icon ${Styles.pNavLinkIcon}`}
                      src={arrow01}
                      alt=""
                      role="presentation"
                    />
                  </a>
                </li>
                <li className={Styles.pNavItem}>
                  <a
                    className={Styles.pNavLink}
                    href="https://www.tam-tam.co.jp/service/"
                    rel="noopener noreferrer"
                  >
                    <span className={Styles.pNavTitle}>サービス案内</span>
                    <span className={Styles.pNavSubTitle}>SERVICES</span>
                    <img
                      className={`icon ${Styles.pNavLinkIcon}`}
                      src={arrow01}
                      alt=""
                      role="presentation"
                    />
                  </a>
                </li>
                <li className={Styles.pNavItem}>
                  <a
                    className={Styles.pNavLink}
                    href="https://www.tam-tam.co.jp/about/"
                    rel="noopener noreferrer"
                  >
                    <span className={Styles.pNavTitle}>TAMについて</span>
                    <span className={Styles.pNavSubTitle}>ABOUT</span>
                    <img
                      className={`icon ${Styles.pNavLinkIcon}`}
                      src={arrow01}
                      alt=""
                      role="presentation"
                    />
                  </a>
                </li>
                <li className={Styles.pNavItem}>
                  <a
                    className={Styles.pNavLink}
                    href="https://www.tam-tam.co.jp/recruit/"
                    rel="noopener noreferrer"
                  >
                    <span className={Styles.pNavTitle}>採用情報</span>
                    <span className={Styles.pNavSubTitle}>RECRUIT</span>
                    <img
                      className={`icon ${Styles.pNavLinkIcon}`}
                      src={arrow01}
                      alt=""
                      role="presentation"
                    />
                  </a>
                </li>
                <li className={Styles.pNavItem}>
                  <a
                    className={Styles.pNavLink}
                    href="https://www.tam-tam.co.jp/stories/"
                    rel="noopener noreferrer"
                  >
                    <span className={Styles.pNavTitle}>記事・トピック</span>
                    <span className={Styles.pNavSubTitle}>STORIES</span>
                    <img
                      className={`icon ${Styles.pNavLinkIcon}`}
                      src={arrow01}
                      alt=""
                      role="presentation"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className={Styles.pNavSubMenu}>
              <ul className={`${Styles.pNavList} ${Styles.uOnlyPc}`}>
                <li className={Styles.pNavItem}>
                  <a className={Styles.pNavLink} href="/">
                    <span className={Styles.pNavTitle}>ホーム</span>
                    <span className={Styles.pNavSubTitle}>HOME</span>
                    <img
                      className={`icon ${Styles.pNavLinkIcon}`}
                      src={arrow01}
                      alt=""
                      role="presentation"
                    />
                  </a>
                </li>
              </ul>
              <div className={Styles.pNavSns}>
                <p className={Styles.pNavSnsText}>FOLLOW US</p>
                <ul className={Styles.pNavSnsList}>
                  <li className={Styles.pNavSnsItem}>
                    <a
                      className={Styles.pNavSnsLink}
                      href="https://www.facebook.com/TAM.Inc"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Facebook"
                    >
                      <img
                        className={`icon ${Styles.pNavSnsIcon}`}
                        src={snsF}
                        alt=""
                        role="presentation"
                      />
                    </a>
                  </li>
                  <li className={Styles.pNavSnsItem}>
                    <a
                      className={Styles.pNavSnsLink}
                      href="https://twitter.com/tam_koho"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="X (Twitter)"
                    >
                      <img
                        className={`icon ${Styles.pNavSnsIcon}`}
                        src={snsX}
                        alt=""
                        role="presentation"
                      />
                    </a>
                  </li>
                  <li className={Styles.pNavSnsItem}>
                    <a
                      className={Styles.pNavSnsLink}
                      href="https://www.instagram.com/taminc/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Instagram"
                    >
                      <img
                        className={`icon ${Styles.pNavSnsIcon}`}
                        src={snsI}
                        alt=""
                        role="presentation"
                      />
                    </a>
                  </li>
                  <li className={Styles.pNavSnsItem}>
                    <a
                      className={Styles.pNavSnsLink}
                      href="https://vimeo.com/taminc"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="Vimeo"
                    >
                      <img
                        className={`icon ${Styles.pNavSnsIcon}`}
                        src={snsV}
                        alt=""
                        role="presentation"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
